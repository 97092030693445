@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .section-min-height {
    min-height: calc(100vh - 68px);
  }
  .toggle-btn {
    @apply [&>div::before]:translate-y-0 [&>div::before]:rotate-45
        [&>div::after]:translate-y-0 [&>div::after]:-rotate-45 [&>div]:bg-transparent [&>div]:rotate-[180deg];
  }
}

body {
  background: #121111;
}
.neumorphism-1 {
  box-shadow: 5px 5px 10px #0d0c0c, -5px -5px 10px #171616;
}

.neumorphism-2 {
  box-shadow: inset 5px 5px 10px #0d0c0c, inset -5px -5px 10px #171616;
}

.neumorphism-3 {
  box-shadow: 10px -10px 20px #0c0c0c, -10px 10px 20px #181616;
}
.box-shadow {
  border-radius: 20px;
  border: 2px solid white;
  background: #121111;
  box-shadow: 5px 5px white;
}
.shadow-solid {
  box-shadow: 3px 3px white;
}
.shadow-solid:active {
  box-shadow: none;
  transform: translate(3px, 3px);
}

.nav_style {
  position: relative;
}
.nav_style::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 0;
  height: 2px;
  left: 0;
  bottom: -3px;
  transition: 0.3s;
}
.nav_style:hover::after {
  width: 100%;
}
/* ===================================== START BUTTON EFFECT ===================== */
.button {
  padding: 0.1em 0.25em;
  width: 13em;
  height: 4.2em;
  background-color: #212121;
  border: 0.08em solid #fff;
  border-radius: 0.3em;
  font-size: 12px;
}

.button span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0.4em;
  width: 8.25em;
  height: 2.5em;
  background-color: #212121;
  border-radius: 0.2em;
  font-size: 1.5em;
  color: #fff;
  border: 0.08em solid #fff;
  -webkit-box-shadow: 0 0.4em 0.1em 0.019em #fff;
  box-shadow: 0 0.4em 0.1em 0.019em #fff;
}

.button span:hover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translate(0, 0.4em);
  -ms-transform: translate(0, 0.4em);
  transform: translate(0, 0.4em);
  -webkit-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
}

.button span:not(hover) {
  -webkit-transition: all 1s;
  transition: all 1s;
}

/* ===================================== CARD RESULT EFFECT ===================== */
.card {
  overflow: visible;
  width: 230px;
  height: 342px;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 2000ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 20px;
}

.front,
.back {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 20px;
  overflow: hidden;
}

.back,
.front {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.front::before,
.back::before {
  position: absolute;
  content: " ";
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(
    90deg,
    transparent,
    #ff9966,
    #ff9966,
    #ff9966,
    #ff9966,
    transparent
  );
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.content-active {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
  color: white;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* ===================================== CARD RESULT EFFECT ===================== */

.btn {
  position: relative;
  padding: 1em 1.8em;
  outline: none;
  border: 1px solid #303030;
  background: #212121;
  color: #ff8866;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.btn:hover {
  box-shadow: 0 0 10px #ff8866, 0 0 25px #ff8866, 0 0 50px #ff8866;
  transition-delay: 0.6s;
}

.btn span {
  position: absolute;
}

.btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ff8866);
}

.btn:hover span:nth-child(1) {
  left: 100%;
  transition: 0.7s;
}

.btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ff8866);
}

.btn:hover span:nth-child(3) {
  right: 100%;
  transition: 0.7s;
  transition-delay: 0.35s;
}

.btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ff8866);
}

.btn:hover span:nth-child(2) {
  top: 100%;
  transition: 0.7s;
  transition-delay: 0.17s;
}

.btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ff8866);
}

.btn:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.7s;
  transition-delay: 0.52s;
}

/* ===================================== FINAL CARD LOADER EFFECT ===================== */

.eye {
  width: 20px;
  height: 8px;
  background-color: rgba(240, 220, 220, 1);
  border-radius: 0px 0px 20px 20px;
  position: relative;
  left: 10px;
  top: 40px;
  box-shadow: 40px 0px 0px 0px rgba(240, 220, 220, 1);
}

.head {
  backface-visibility: hidden;
  position: relative;
  margin: -250px auto;
  width: 80px;
  height: 80px;
  background-color: #111;
  border-radius: 50px;
  box-shadow: inset -4px 2px 0px 0px rgba(240, 220, 220, 1);
  animation: headAnim 1.5s infinite alternate;
  animation-timing-function: ease-out;
}

.body {
  position: relative;
  margin: 90px auto;
  width: 140px;
  height: 120px;
  background-color: #111;
  border-radius: 50px/25px;
  box-shadow: inset -5px 2px 0px 0px rgba(240, 220, 220, 1);
  animation: bodyAnim 1.5s infinite alternate;
  animation-timing-function: ease-out;
}

@keyframes headAnim {
  0% {
    top: 0px;
  }

  50% {
    top: 10px;
  }

  100% {
    top: 0px;
  }
}

@keyframes bodyAnim {
  0% {
    top: -5px;
  }

  50% {
    top: 10px;
  }

  100% {
    top: -5px;
  }
}

.circ {
  backface-visibility: hidden;
  margin: 60px auto;
  width: 180px;
  height: 180px;
  border-radius: 0px 0px 50px 50px;
  position: relative;
  z-index: -1;
  left: 0%;
  top: 20%;
  overflow: hidden;
  transform: scale(1.8);
}

.hands {
  margin-top: 140px;
  width: 120px;
  height: 120px;
  position: absolute;
  background-color: #111;
  border-radius: 20px;
  box-shadow: -1px -4px 0px 0px rgba(240, 220, 220, 1);
  transform: rotate(45deg);
  top: 75%;
  left: 16%;
  z-index: 1;
  animation: bodyAnim 1.5s infinite alternate;
  animation-timing-function: ease-out;
}

.load {
  position: absolute;
  width: 7ch;
  height: 32px;
  text-align: left;
  line-height: 32px;
  margin: -10px auto;
  font-family: "Julius Sans One", sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: rgb(155, 152, 152);
  left: 2%;
  top: 5%;
  animation: fontAnim 3.75s infinite;
  animation-timing-function: ease-out;
  word-wrap: break-word;
  display: block;
  overflow: hidden;
}

@keyframes fontAnim {
  0% {
    width: 7ch;
  }

  16% {
    width: 8ch;
  }

  32% {
    width: 9ch;
  }

  48% {
    width: 10ch;
  }

  64% {
    width: 11ch;
  }

  80% {
    width: 12ch;
  }

  100% {
    width: 13ch;
  }
}

/* ========================= HERO ANIMATION ================ */

.loader-wrapper {
  position: relative;
  top: 0;
  left: -110px;
  bottom: 0;
  right: 0;
  margin: auto;
}

.loader-wrapper .packman::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: white;
  border-radius: 100px 100px 0 0;
  transform: translate(-50%, -50%);
  animation: pac-top 0.5s linear infinite;
  transform-origin: center bottom;
}

.loader-wrapper .packman::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: white;
  border-radius: 0 0 100px 100px;
  transform: translate(-50%, 50%);
  animation: pac-bot 0.5s linear infinite;
  transform-origin: center top;
}

@keyframes pac-top {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0);
  }
}

@keyframes pac-bot {
  0% {
    transform: translate(-50%, 50%) rotate(0);
  }

  50% {
    transform: translate(-50%, 50%) rotate(30deg);
  }

  100% {
    transform: translate(-50%, 50%) rotate(0);
  }
}

.dots .dot {
  position: absolute;
  z-index: -1;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4db5ff;
}

.dots .dot:nth-child(1) {
  left: 90px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(2) {
  left: 60px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(3) {
  left: 30px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(4) {
  left: 10px;
  animation: dot-stage2 0.5s infinite;
}

@keyframes dot-stage1 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-24px, 0);
  }
}

@keyframes dot-stage2 {
  0% {
    transform: scale(1);
  }

  5%,
  100% {
    transform: scale(0);
  }
}

/* ============================================== */
.tilt-box-wrap {
  width: 400px;
  height: 400px;
  position: relative;
  transition: all 0.6s ease-out;
}
.tilt-box-wrap:hover {
  transition: all 0.3s linear;
  transform: scale(1.1);
}
.tilt-box {
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  color: #fff;
  font-size: 90px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.6s ease-out;
  transform: rotateX(0deg) rotateY(0deg);
  transform-style: preserve-3d;
}
.tilt-box strong {
  transform: translateZ(40px);
}
.t_over {
  width: 33.333%;
  height: 33.333%;
  position: absolute;
  z-index: 1;
}

.t_over:nth-child(1) {
  left: 0;
  top: 0;
}
.t_over:nth-child(2) {
  left: 33.333%;
  top: 0;
}
.t_over:nth-child(3) {
  left: 66.666%;
  top: 0;
}
.t_over:nth-child(4) {
  left: 0;
  top: 33.333%;
}
.t_over:nth-child(5) {
  left: 33.333%;
  top: 33.333%;
}
.t_over:nth-child(6) {
  left: 66.666%;
  top: 33.333%;
}
.t_over:nth-child(7) {
  left: 0;
  top: 66.666%;
}
.t_over:nth-child(8) {
  left: 33.333%;
  top: 66.666%;
}
.t_over:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}
.t_over:nth-child(1):hover ~ .tilt-box {
  transform: rotateX(-20deg) rotateY(20deg);
}
.t_over:nth-child(2):hover ~ .tilt-box {
  transform: rotateX(-20deg) rotateY(0deg);
}
.t_over:nth-child(3):hover ~ .tilt-box {
  transform: rotateX(-20deg) rotateY(-20deg);
}
.t_over:nth-child(4):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(20deg);
}
.t_over:nth-child(5):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(0deg);
}
.t_over:nth-child(6):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(-20deg);
}
.t_over:nth-child(7):hover ~ .tilt-box {
  transform: rotateX(20deg) rotateY(20deg);
}
.t_over:nth-child(8):hover ~ .tilt-box {
  transform: rotateX(20deg) rotateY(0deg);
}
.t_over:nth-child(9):hover ~ .tilt-box {
  transform: rotateX(20deg) rotateY(-20deg);
}

/* ============================================ SWIPER EFFECT =========================== */
.swiper {
  max-width: 230px;
  height: 345px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}

/* ============================================= ABOUT TITLE ANIMATION =========================== */
.spinner {
  height: 50px;
  width: max-content;
  font-weight: 600;
  font-family: monospace;
  color: white;
  filter: drop-shadow(0 0 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.spinner span {
  animation: loading6454 1.75s ease infinite;
}

.spinner span:nth-child(2) {
  animation-delay: 0.25s;
}

.spinner span:nth-child(3) {
  animation-delay: 0.5s;
}

.spinner span:nth-child(4) {
  animation-delay: 0.75s;
}

.spinner span:nth-child(5) {
  animation-delay: 1s;
}

.spinner span:nth-child(6) {
  animation-delay: 1.25s;
}

.spinner span:nth-child(7) {
  animation-delay: 1.5s;
}

.spinner span:nth-child(8) {
  animation-delay: 1.75s;
}

.spinner span:nth-child(9) {
  animation-delay: 2s;
}

.spinner span:nth-child(10) {
  animation-delay: 2.25s;
}

.spinner span:nth-child(11) {
  animation-delay: 2.5s;
}

@keyframes loading6454 {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

/* ======================== INSTRUCTION CONTENT  =========================*/
.content-hide {
  overflow: hidden;
  max-height: 80px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.content-show {
  overflow: hidden;
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
